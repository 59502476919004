.logo_navTitle {
  width: 100%;
  height: auto;
  max-height: 60px;
  object-fit: contain;
}

.border-right-only {
  border: none;
  border-right: 1px solid rgb(192, 192, 192);
  background: transparent;
  cursor: pointer;
  font-size: 1rem;
  line-height: 50px;
}
/* for the menu popup on navbar   */
.p-menu.p-menu-overlay {
  z-index: 9999 !important;
}
